import { graphql, useStaticQuery } from 'gatsby';

import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

export const useSiteMetadata = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
					image
					siteUrl
				}
			}
		}
	`);
	return data.site.siteMetadata;
};

export interface SeoProps {
	title?: string;
	description?: string;
	pathname?: string;
	children?: React.ReactNode;
	image?: string;
}

const buildUrl = (base: string, path: string) => {
	// replace duplicate slashes in url but not the duouble slash in the https:// using negative lookbehind
	return (base + '/' + path).replace(/(?<!:)\/{2,}/g, '/');
};

const HrefLang = ({
	siteUrl,
	pathname,
	availableLanguages,
}: {
	siteUrl: string;
	pathname?: string;
	availableLanguages: string[];
}) => {
	const links = availableLanguages.map((lang) => {
		return (
			<link
				key={lang}
				rel="alternate"
				hrefLang={lang}
				href={buildUrl(buildUrl(siteUrl, lang === 'de' ? '' : lang), pathname ?? '')}
			/>
		);
	});
	// add german as fallback with x-default
	links.push(<link rel="alternate" hrefLang="x-default" href={buildUrl(siteUrl, '')} />);
	return <>{links}</>;
};

export const Seo = ({ title, description, pathname, children, image }: SeoProps) => {
	const { t } = useTranslation('Seo');
	const { languages, language } = useI18next();

	const defaultMeta = useSiteMetadata();

	const seo = {
		title: title || t('Title', { defaultValue: defaultMeta.title }),
		description:
			description || t('Description', { defaultValue: defaultMeta.description }),
		image: buildUrl(defaultMeta.siteUrl, image || defaultMeta.image),
		url: buildUrl(defaultMeta.siteUrl, buildUrl(language, pathname || '')),
	};

	return (
		<>
			<html lang={language} />
			{/* <script
				defer
				data-domain="konfidal.eu"
				data-api="/proxy/plausible/api/event"
				src="https://konfidal.eu/proxy/plausible/js/script.outbound-links.js"
			></script> */}
			<HrefLang
				siteUrl={defaultMeta.siteUrl}
				pathname={pathname}
				availableLanguages={languages}
			/>
			<title>{seo.title}</title>
			<meta name="title" content={seo.title} />
			<meta name="language" content={language} />
			<meta name="description" content={seo.description} />
			<meta name="image" content={seo.image} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content={seo.title} />
			<meta name="twitter:url" content={seo.url} />
			<meta name="twitter:description" content={seo.description} />
			<meta name="twitter:image" content={seo.image} />
			<meta name="og:title" content={seo.title} />
			<meta name="og:url" content={seo.url} />
			<meta name="og:description" content={seo.description} />
			<meta name="og:image" content={seo.image} />
			<meta name="image" property="og:title" content={seo.title} />
			<meta name="url" property="og:url" content={seo.url} />
			<meta name="description" property="og:description" content={seo.description} />
			<meta name="image" property="og:image" content={seo.image} />
			{children}
		</>
	);
};
