import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { ButtonRecipe } from '../components/Button.css';
import FaqSection, { FaqHeader } from '../components/FaqSection';
import Layout, { Section } from '../components/Layout';
import Link from '../components/Link';
import { Seo } from '../components/Seo';
import { Title } from '../components/Text';

export const FaqPage = ({
	data: { allFaqJson },
}: {
	data: {
		locales: { edges: Queries.LocaleEdge[] };
		allFaqJson: { nodes: Queries.FaqJson[] };
	};
}) => {
	const { t } = useTranslation('FaqPage');
	return (
		<Layout padding={false}>
			<Section width="wide" gap="larger">
				<Section width="wide" padding>
					<FaqHeader
						width="wide"
						actions={
							<Link
								to="https://my.meetergo.com/martinmeng/hinschg"
								className={ButtonRecipe({ variant: 'primary' })}
							>
								{t('CtaText')}
							</Link>
						}
					/>
				</Section>
				<Section width="fill" padding={false}>
					<Section width="fill" padding>
						<Title level="subsection">{t('Businesses.Title')}</Title>
					</Section>
					<FaqSection
						onlyFaq
						content={allFaqJson.nodes[0].entries.filter(
							(e) =>
								e.pages.includes('/') ||
								e.pages.includes('/consulting') ||
								e.pages.includes('/software-business') ||
								e.pages.includes('training') ||
								e.pages.includes('/full-service'),
						)}
					/>
				</Section>
				<Section width="fill" padding={false}>
					<Section width="fill" padding>
						<Title level="subsection">{t('Consultants.Title')}</Title>
					</Section>
					<FaqSection
						onlyFaq
						content={allFaqJson.nodes[0].entries.filter((e) =>
							e.pages.includes('/software-consult'),
						)}
					/>
				</Section>
			</Section>
		</Layout>
	);
};
export default FaqPage;

export const Head = () => {
	return <Seo title="FAQ" description="Frequently aske questions" />;
};

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		allFaqJson(filter: { language: { eq: $language } }) {
			nodes {
				entries {
					title
					content
					pages
				}
			}
		}
	}
`;
